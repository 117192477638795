
/*
 *    Config
 */

var Config = [{
  id: 'codemit',
  svgOptions: {
    strokeColor: '#333'
  }
}, {
  id: 'snowflakes',
  svgOptions: {
    strokeColor: '#333'
  }
}, {
  id: 'dokokara',
  svgOptions: {
    strokeColor: '#FFF'
  }
}, {
  id: 'metloc',
  svgOptions: {
    strokeColor: '#666'
  }
}, {
  id: 'oyatsu',
  svgOptions: {
    strokeColor: '#666',
    duration: 3000
  }
}, {
  id: 'about'
//}, {
//  id: 'contact'
}];

module.exports = Config;

